import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/unittestbot.github.io/unittestbot.github.io/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "vs-code-plugin-description"
    }}>{`VS Code plugin description`}</h1>
    <p>{`It is intended that most users operate with UTBot via its Visual Studio Code plugin. UTBot and its VS Code plugin
communicate through gRPC protocol.`}</p>
    <p><img alt="VSCode plugin" src="https://github.com/UnitTestBot/unittestbot.github.io/raw/source/resources/images/vscodePlugin.PNG" /></p>
    <ul>
      <li parentName="ul">{`Tests generation commands to VSCode Command Palette. UTBot can generate tests for:`}
        <ul parentName="li">
          <li parentName="ul">{`whole project`}</li>
          <li parentName="ul">{`file`}</li>
          <li parentName="ul">{`function`}</li>
          <li parentName="ul">{`line, i.e. generate a test that forces the execution to run through the selected code line (if such a test exists)`}</li>
          <li parentName="ul">{`assertion, i.e. generate test that fails selected assertion (if exists)`}</li>
          <li parentName="ul">{`output, i.e. generate a test that forces selected function to return a specific value (if exists).`}</li>
        </ul>
      </li>
      <li parentName="ul">{`VSCode Lenses with actions for running a single test, all tests and test suite for one source file`}</li>
      <li parentName="ul">{`Icons in VSCode Side Bar, which show UTBot status`}</li>
      <li parentName="ul">{`VSCode Activity Bar item for selecting project sources and project targets`}</li>
      <li parentName="ul">{`UTBot extension settings for customizing UTBot behaviour.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      